import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import MediaCarousel from 'dpl/components/MediaCarousel';

import PhotoAlbumThumbnailButton from '../PhotoAlbum/PhotoAlbumThumbnailButton';

export default function MediaGridCarousel({
  selectedMediaIdx,
  mediaFiles,
  onClose,
  bulletsContainerClassName,
  mobileCompact
}) {
  const muxRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(
    mediaFiles[selectedMediaIdx] || null
  );

  function setImage(id) {
    const idx = mediaFiles.findIndex(file => file.id === id);
    setCurrentFile(mediaFiles[idx]);
  }

  return (
    <MiniOverlayModal
      className="MediaGridCarouselModal bg-black-90"
      contentClassName="bg-black-90 overflow-hidden ph5-md"
      onCloseClick={onClose}
      maxWidth="100%"
      scrollableContent
      mobileCompact={mobileCompact}
      fullScreen
    >
      <div className="MediaGridCarousel br4 h-100 pv10-md ph5 pv5">
        <div className="flex-auto w-100 h-100 flex flex-column">
          <div className="flex-auto relative">
            <div className="w-100 h-100 absolute bg-black-90 pv10-md pv5">
              <MediaCarousel
                muxRef={muxRef}
                files={mediaFiles}
                lazy={false}
                currentFile={currentFile}
                onFileChange={setImage}
                smartImageProps={{
                  className:
                    'transform-center left-0 bottom-0 right-0 top-0 max-h-100 h-100 h-auto center',
                  imageOnlyClassName: 'max-h-100 h-auto',
                  wrapperOnlyClassName: 'w-100',
                  loadingClass: 'h-100 loading-spinner',
                  maxWidth: '800px'
                }}
                bulletsContainerClassName={bulletsContainerClassName}
              />
            </div>
          </div>
          <ul className="flex overflow-x-auto w-100">
            {mediaFiles.map(image => (
              <li className="f0" key={image.id}>
                <PhotoAlbumThumbnailButton
                  onClick={() => setImage(image.id)}
                  isSelected={image === currentFile}
                  image={image}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </MiniOverlayModal>
  );
}

MediaGridCarousel.propTypes = {
  selectedMediaIdx: PropTypes.number,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  bulletsContainerClassName: PropTypes.string,
  mobileCompact: PropTypes.bool
};

MediaGridCarousel.defaultProps = {
  selectedMediaIdx: null,
  bulletsContainerClassName: '',
  mobileCompact: true
};
